@import "../variables.modules";

nav {
  margin-top: 30px;
  transition: $transition;
}

.active {
  border-bottom: 5px solid;
  border-image-slice: 1;
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}
.buttonflag {
  border: none;
}
