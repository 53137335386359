.pagination{
    display: flex;
    justify-content: center;
    gap: 56px;
}
.blogger{
    color: white;
}

li{
    list-style-type: none;
}