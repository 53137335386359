.ptwitter{
    background: linear-gradient(90deg, rgba(75,66,227,1) 0%, rgba(129,129,236,1) 35%, rgba(0,212,255,1) 100%);
      padding: 19px;
      font-size: 13px;
      display: flex;
      justify-content: flex-start;
      border-radius: 10px;
      margin-top: 25px;
      color: white;
      font-weight: bold;
      width: 21rem;
      box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  }

.logoTwitter{
    font-size: 24px;
    width: 65%;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
}
.textorecentes{
    display: flex;
    align-items: center;
    align-content: center;
}
.twitter-container{
    width: 100%;
}
.tweets-container{
    color: white;
    padding: 20px;
    border-radius: 20px;
}

.tweets{
    font-size: 19px;
    display: flex;
}

.containerbox{
    margin-top: 2rem;
}